body {
  margin: 0;
}

html, body {
    width:  100%;
    height: 100%;
    margin: 0;
    border: 0;
    overflow: hidden; /*  Disable scrollbars */
    display: block;  /* No floating content on sides */
}

canvas.webapp {
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 0px;
    border: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
}
